import { Directive, ElementRef, OnInit} from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Directive({
	selector: '[authSrc]',
	inputs: ['value: authSrc'],
})
export class AuthSrcDirective implements OnInit {
	loading = '/assets/app/media/img/spinningwheel.gif';
	_value: string = undefined;

	set value(val: string) {
		if (typeof val !== 'string' || this._value === val) {
			return;
		}

		this.setImage(val);
		this._value = val;
	}

	constructor(
		private el: ElementRef,
		private http: HttpClient
	) {
	}

	ngOnInit(): void {
		this.el.nativeElement.src = this.loading;
	}

	setImage(val: string) {
		if (!val.startsWith('http')) {
			const seperator = !val.startsWith('/') ? '/' : '';

			val = environment.apiUrl + seperator + val;
		}

		this.http.get(val, { responseType: 'blob' }).subscribe(data => {
			if (data) {
				this.el.nativeElement.src = URL.createObjectURL(data);
			}
		});
	}
}
