import { QuickSearchDb } from './fake-db/quick-search';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import { AuthFakeDb } from './fake-db/auth';
import { MessengerDb } from './fake-db/messenger';
import { LogsDb } from './fake-db/logs';
import { CarsDb } from './fake-db/cars';
import { PackagesDb } from './fake-db/packages';

@Injectable()
export class FakeApiService implements InMemoryDbService {
	constructor() { }

	createDb(): {} | Observable<{}> {
		return {

			// login and account
			login: AuthFakeDb.users,
			refresh: AuthFakeDb.tokens,
			register: AuthFakeDb.users,
			// messenger
			messenger: MessengerDb.messages,

			// logs
			logs: LogsDb.logs,
			quick_search: QuickSearchDb.quickSearchHtml,
			// data-table
			cars: CarsDb.cars,

			// packages
			outgoingPackagesToday: PackagesDb.outgoingPackagesToday,
			returningPackages: PackagesDb.returningPackages,
			incomingPackages: PackagesDb.incomingPackages,
			incomingPackageDetailById: PackagesDb.incomingPackageDetailById,
			outgoingPackages: PackagesDb.outgoingPackages,
			storageRooms: PackagesDb.rooms,
			sectionsByRoomCode: PackagesDb.sectionsByRoomCode,
			subSectionsBySectionCode: PackagesDb.subSectionsBySectionCode,
			getRevenueInfo: PackagesDb.revenueInfo,
			guests: PackagesDb.guests
		};
	}
}
