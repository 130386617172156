import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject, Component } from '@angular/core';

@Component({
	selector: 'm-confirmation-dialog',
	template: `
	<div class="col-xl-12">
	<div class="col-xl-12">
		<br />
		<h1 mat-dialog-title>{{data.caption}}</h1>
		<div mat-dialog-content>
		  <p>{{data.text}}</p>
		</div>
		<div mat-dialog-actions>
		  <button mat-button (click)="onNoClick()">Cancel</button>
		  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
		</div>
		<br />
	</div>
	</div>`,
})
export class ConfirmationDialogComponent {

	constructor(
		public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
	) {

	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}
}

export interface ConfirmationDialogData {
	text: string;
	caption: string;
}
