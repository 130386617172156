export class UserInfo {
    sub: string;
    name: string;
    picture: string;
    email: string;
    emailverified: boolean;
    roles: string[];

    public hasRole(role: string): boolean {
        return this.roles.indexOf(role) !== -1;
    }
}
