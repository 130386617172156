import { ConfigModel } from '../core/interfaces/config';

export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: [

				]
			},
			aside: {
				self: {},
				items: [
					{
						title: 'Language Management',
						desc: 'Create Language',
						root: true,
						icon: 'fa fa-globe',
						page: 'language-management',
						translate: 'MENU.LANGUAGE_MANAGEMENT',
					},
					{
						title: 'Menu Management',
						desc: 'Create Menu',
						root: true,
						icon: 'flaticon-map',
						page: 'menu-management',
						translate: 'MENU.MENU_MANAGEMENT',
					},
					{
						title: 'Main Page',
						desc: 'Create Main Page',
						root: true,
						icon: 'fa fa-chalkboard',
						page: 'main-page-management',
						translate: 'MENU.MAIN_PAGE_MANAGEMENT',
					},
					{
						title: 'Page List',
						desc: 'Create Page',
						root: true,
						icon: 'fa fa-newspaper',
						page: 'page-management',
						translate: 'MENU.PAGE_MANAGEMENT',
					},
					{
						title: 'Contact Page',
						desc: 'Edit Contact Page',
						root: true,
						icon: 'fa fa-address-card',
						page: 'contact-page-management',
						translate: 'MENU.CONTACT_PAGE_MANAGEMENT',
					},
					{
						title: 'Search Page',
						desc: 'Edit Search Page',
						root: true,
						icon: 'fa fa-search',
						page: 'search-page-management',
						translate: 'MENU.SEARCH_PAGE_MANAGEMENT',
					},
					{
						title: 'File Management',
						desc: 'Upload Files',
						root: true,
						icon: 'fa fa-file',
						page: 'file-management',
						translate: 'MENU.FILE_MANAGEMENT',
					},
				]
			}
		};
	}
}
