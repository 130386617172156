import { Directive, ElementRef, OnInit, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../services/utils.service';

const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;

@Directive({
	selector: '[authDownload]',
	inputs: ['value: authDownload'],
})
export class AuthDownloadDirective implements OnInit {
	_value: string = undefined;

	@Input() params: any;

	@Output() downloadStart = new EventEmitter<Observable<Blob>>();

	set value(val: string) {
		if (typeof val !== 'string' || this._value === val) {
			return;
		}

		this._value = val;
	}

	constructor(
		private el: ElementRef,
		private http: HttpClient,
		private utilsService: UtilsService
	) {
	}

	ngOnInit(): void {
	}

	@HostListener('click')
	download() {
		if (!this._value.startsWith('http')) {
			const seperator = !this._value.startsWith('/') ? '/' : '';

			this._value = environment.apiUrl + seperator + this._value;
		}

		let _params;

		if (typeof this.params === 'object') {
			_params = this.utilsService.urlParam(this.params);
		} else if (typeof this.params === 'function') {
			_params = this.utilsService.urlParam(this.params());
		}

		const observable = this.http.get(this._value + '?' + _params, { responseType: 'blob' });

		this.downloadStart.emit(observable);

		observable.subscribe(data => {
			if (data) {
				let sUrl = URL.createObjectURL(data);

				// iOS devices do not support downloading. We have to inform user about this.
				if (/(iP)/g.test(navigator.userAgent)) {
					// alert('Your device does not support files downloading. Please try again in desktop browser.');
					window.open(sUrl, '_blank');
					return false;
				}

				// If in Chrome or Safari - download via virtual link click
				if (isChrome || isSafari) {
					// Creating new link node.
					const link = document.createElement('a');
					link.href = sUrl;
					link.setAttribute('target', '_blank');

					if (link.download !== undefined) {
						// Set HTML5 download attribute. This will prevent file from opening if supported.
						const fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);

						link.download = fileName;
					}

					// Dispatching click event.
					if (document.createEvent) {
						const e = document.createEvent('MouseEvents');
						e.initEvent('click', true, true);
						link.dispatchEvent(e);
						return true;
					}
				}

				// Force file download (whether supported by server).
				if (sUrl.indexOf('?') === -1) {
					sUrl += '?download';
				}

				window.open(sUrl, '_blank');
			}
		});
	}
}
