import { Component, Output, EventEmitter, ChangeDetectorRef, Input, ViewChild, TemplateRef } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material';
import { AngularMaterialIcons } from '../../../../../models/angular-material-icons.model';

@Component({
  selector: 'm-icon-selector',
  templateUrl: './icon-selector.component.html',
  styleUrls: ['./icon-selector.component.css']
})
export class IconSelectorComponent {
  // tslint:disable-next-line: no-output-rename
  @Output('selectedIconChange') selectedIconEvent = new EventEmitter<string>();
  _selectedIcon = 'tag_faces';
  @Input() set selectedIcon(value: string) {
    this._selectedIcon = value && value.length ? value : 'tag_faces';
  }

  icons = AngularMaterialIcons;
  dialogRef: MatDialogRef<{}, any>;
  filterText: string = '';

  @ViewChild('dialog') dialogTemplate: TemplateRef<any>;

  constructor(
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {

  }

  public selectIcon(icon: string) {
    this.dialogRef.close({ data: icon });
  }

  public closeDialog() {
    this.dialogRef.close({ data: '' });
  }

  public openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';

    this.dialogRef = this.dialog.open(this.dialogTemplate, dialogConfig);
    this.dialogRef.afterClosed().subscribe(result => {
      this.selectedIcon = result.data;
      this.selectedIconEvent.emit(result.data);
      this.cd.detectChanges();
    });
  }

  filter(): (string) => boolean {
    return (icon: string) => icon.includes(this.filterText);
  }
}
