import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import { Observable, of, Scheduler, from, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';
import { MatSnackBar } from '@angular/material';
import { TokenStorage } from '../auth/token-storage.service';


/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private tokenStorage: TokenStorage
    ) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((error: HttpErrorResponse): Observable<any> => {
            if (error && (error as HttpErrorResponse).status === 401) {

                this.tokenStorage.clear();
                this.router.navigate(['/admin/login']);
                this.authenticationService.logout(true);
            }

            if (error && error.status >= 500) {
                this.snackBar.open(error.error && error.error.Message, 'ok', { verticalPosition: 'top' });
            }

            return throwError(error);
        }));
    }
}