import { Directive, ElementRef, OnInit, EventEmitter, ViewChild, Host, HostListener } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatButton } from '@angular/material';
import { Observable } from 'rxjs';
import { Button } from 'protractor';

export class MatClickEvent {
	deactivateTitle: string;

	constructor(private button: HTMLButtonElement) {
	}

	listen(observable: Observable<any>) {
		const button = this.button;
		const html = button.innerHTML;

		button.innerHTML = this.deactivateTitle || 'Loading...';
		this.button.disabled = true;

		observable.subscribe(data => {
		}, err => {
			button.innerHTML = html;
			this.button.disabled = false;
		}, () => {
			button.innerHTML = html;
			this.button.disabled = false;
		});

		return observable;
	}
}

@Directive({
	selector: '[matClick]',
	outputs: ['event: matClick']
})
export class MatClickDirective implements OnInit {

	event = new EventEmitter<MatClickEvent>();

	constructor(
		private el: ElementRef<HTMLButtonElement>,
	) {
	}

	@HostListener('click')
	click() {
		this.event.next(new MatClickEvent(this.el.nativeElement));
	}

	ngOnInit(): void {
	}
}
