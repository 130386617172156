export class PackagesDb {
    public static outgoingPackagesToday: any = [
        {
            'id': 'c99dc994-b1dd-4dd9-8cc9-a4470135d163',
            'guestName': 'Bob Marley',
            'courier': 'FedEx',
            'service': '3 Day Express Saver',
            'handlingFee': '$15',
            'shipment': true,
            'charging': true
        },
        {
            'id': 'cf98b590-1eaa-453f-a110-7b34c95dac43',
            'guestName': 'Magic Johnson',
            'courier': 'UPS',
            'service': '3 Day Express Saver',
            'handlingFee': '$12',
            'shipment': false,
            'charging': false
        },
        {
            'id': '1588ef75-b1ea-439b-b308-8f15a17f9aaf',
            'guestName': 'Michael Jordan',
            'courier': 'UPS',
            'service': '3 Day Express Saver',
            'handlingFee': '$13',
            'shipment': false,
            'charging': true
        }
    ];

    public static returningPackages: any = [
        {
            'id': 'e05434ba-81d3-43f7-aab3-1431ac55d9a5',
            'trackingNo': '12345',
            'courier': 'FedEx',
            'returned': true
        },
        {
            'id': 'f8aff004-de40-4432-a563-ec6074e817a3',
            'trackingNo': '67897',
            'courier': 'UPS',
            'returned': false
        },
        {
            'id': 'fbf4747c-5566-4bcf-bb04-c1884b242e7c',
            'trackingNo': '12122',
            'courier': 'FedEx',
            'returned': true
        }
    ];

    public static incomingPackages: any = [
        {
            'id': '54431c48-d3e2-40ca-966f-cfa8499f40a7',
            'deliveryDate': '11.11.2018 23:59',
            'guestName': 'Kevin Bruma',
            'roomNo': 654,
            'trackingNumber': '1212121221',
            'courier': 'UPS',
            'status': 'D.List',
            'location': 'A12-B13-C14',
            'handleFee': '$12',
            'charged': true
        },
        {
            'id': '7450bb5d-4090-4db4-904a-212f5c2bd41c',
            'deliveryDate': '12.11.2018 23:59',
            'guestName': 'Alex Ferguson',
            'roomNo': 221,
            'trackingNumber': '689678888',
            'courier': 'UPS',
            'status': 'Delivered',
            'location': 'A12-B13-C14',
            'handleFee': null,
            'charged': false
        },
        {
            'id': '6af3114e-1853-4168-8699-284ac0dcc12d',
            'deliveryDate': '13.11.2018 23:59',
            'guestName': 'Donald Trump',
            'roomNo': 123,
            'trackingNumber': '12312311233',
            'courier': 'UPS',
            'status': 'D.List',
            'location': 'A12-B13-C14',
            'handleFee': '$12',
            'charged': true
        },
        {
            'id': '2028460a-645f-4cac-aa9d-026881b3b555',
            'deliveryDate': '15.11.2018 23:59',
            'guestName': 'Muhammed Ali',
            'roomNo': 222,
            'trackingNumber': '423565666',
            'courier': 'UPS',
            'status': 'D.List',
            'location': 'A14-B17-C11',
            'handleFee': null,
            'charged': false
        }
    ];

    public static incomingPackageDetailById: any =
        {
            'id': '54431c48-d3e2-40ca-966f-cfa8499f40a7',
            'stateId': 1,
            'stateName': 'Scanned',
            'fullName': 'Kevin Bruma',
            'courier': 'UPS',
            'trackingNumber': '1212121221',
            'receivedDate': '11.10.2018',
            'roomId': 'A',
            'sectionId': '1',
            'subSectionId': '2',
            'imageUrl': '../../../../../../assets/package-label-image.jpg'
        };

    public static outgoingPackages: any = [
        {
            'deliveryDate': '11.11.2018 23:59',
            'guestName': 'Robert Widmark',
            'roomNo': 654,
            'trackingNumber': '4324222',
            'courier': 'UPS',
            'status': 'D.List',
            'location': 'A12-B13-C14',
            'totalFee': '$12',
            'charged': true
        },
        {
            'deliveryDate': '10.11.2018 23:59',
            'guestName': 'Sonia Viviani',
            'roomNo': 221,
            'trackingNumber': '689678888',
            'courier': 'UPS',
            'status': 'Delivered',
            'location': 'A12-B13-C14',
            'totalFee': null,
            'charged': false
        },
        {
            'deliveryDate': '13.11.2018 23:59',
            'guestName': 'Will Smith',
            'roomNo': 123,
            'trackingNumber': '21333333',
            'courier': 'UPS',
            'status': 'D.List',
            'location': 'A12-B13-C14',
            'totalFee': '$12',
            'charged': true
        },
        {
            'deliveryDate': '15.11.2018 23:59',
            'guestName': 'Eva Bender',
            'roomNo': 222,
            'trackingNumber': '423565666',
            'courier': 'UPS',
            'status': 'D.List',
            'location': 'A14-B17-C11',
            'totalFee': null,
            'charged': false
        }
    ];

    public static rooms: any = [
        {
            'code': 'A',
            'value': 'Room A'
        },
        {
            'code': 'B',
            'value': 'Room B'
        },
    ];

    public static sectionsByRoomCode: any = [
        {
            roomCode: 'A',
            sections: [
                {
                    'code': '1',
                    'value': 'Cabinet 1'
                },
                {
                    'code': '2',
                    'value': 'Cabinet 2'
                }
            ]

        },
        {
            roomCode: 'B',
            sections: [
                {
                    'code': '1',
                    'value': 'Cabinet 1'
                },
                {
                    'code': '2',
                    'value': 'Cabinet 2'
                }
            ]

        }
    ];

    public static subSectionsBySectionCode: any = [
        {
            sectionCode: '1',
            subSections: [
                {
                    'code': '101',
                    'value': 'Shelf A'
                },
                {
                    'code': '201',
                    'value': 'Shelf B'
                }
            ]

        },
        {
            sectionCode: '2',
            subSections: [
                {
                    'code': '101',
                    'value': 'Shelf A'
                },
                {
                    'code': '205',
                    'value': 'Shelf Q'
                }
            ]

        }
    ];


    public static revenueInfo: any = {
        'revenueToday': 300,
        'waivedRevenueToday': 200
    };

    public static guests = [
        {
            'id': 1,
            'firstName': 'Will',
            'lastName': 'Smith',
            'email': 'will.smith@gmail.com'
        }
    ];
}
